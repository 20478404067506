// @ts-check

/**
 * Expands grouped Tailwind classes into their full form.
 * @param {string} input - The input string containing Tailwind classes.
 * @returns {string} The expanded classes as a space-separated string.
 * @throws {Error} If there are invalid groups or unclosed groups.
 */
function expandClasses(input) {
  const items = input.split(' ').filter(Boolean)
  /** @type {string[]} */
  const result = []
  /** @type {string[]} */
  const groups = []

  for (const item of items) {
    switch (true) {
      // Single grouped className, i.e. hover:(mb-3)
      case item.includes(':(') && item.endsWith(')'): {
        // remove first ( and last )
        const className = item.replace(':(', ':').slice(0, -1)

        result.push([...groups, className].join(''))
        break
      }

      // Start of a group
      case item.includes(':('): {
        const [group, className] = item.split('(')
        if (typeof group !== 'string')
          throw new Error(`Invalid group: ${group}`)
        if (typeof className !== 'string')
          throw new Error(`Invalid className: ${className}`)

        groups.push(group)
        result.push([...groups, className].join(''))
        break
      }

      // End of a group
      case item.endsWith(')'): {
        const className = item.replace(/\)+$/g, '')
        if (typeof className !== 'string')
          throw new Error(`Invalid className: ${className}`)

        result.push([...groups, className].join(''))

        // Check for unclosed groups
        if (groups.length === 0)
          throw new Error(
            `Unclosed group. Make sure all groups are closed. Input: ${input}`,
          )

        // Since we've reached the end of a group, we don't need to add it as a prefix anymore
        // and each ending ) indicates a closed group which is why we pop as many groups as we've seen ending `)`
        const groupEndCount = (item.match(/\)+$/g)?.length || 0) + 1
        groups.splice(-groupEndCount)
        break
      }

      default:
        result.push([...groups, item].join(''))
        break
    }
  }

  return result.join(' ')
}

module.exports = { expandClasses }
