// @ts-check

// Note for future optimisations:
// Once twin.macro is removed, we can convert out tailwind.config to a typescript file
// which also means this file can be converted to typescript.

/* eslint-disable @typescript-eslint/no-var-requires */
const { expandClasses } = require('./expandClasses')

const TAILWIND_ATTR_AND_STRING_LITERAL_REGEX = /tx="([^"]*)"|tx`([^`]*)`/g

/**
 * Transforms content by extracting and expanding Tailwind classes.
 * @param {string} content - The input content to transform.
 * @returns {string} The expanded Tailwind classes joined as a string.
 */
function transformer(content) {
  const matchAll = content.matchAll(TAILWIND_ATTR_AND_STRING_LITERAL_REGEX)

  /** @type {string[]} */
  const classes = []
  for (const [, txProp, twString] of Array.from(matchAll)) {
    txProp && classes.push(expandClasses(txProp))
    twString && classes.push(expandClasses(twString))
  }

  return classes.join(' ')
}

module.exports = { transformer }
